import React from 'react'
import {Link} from 'react-router-dom'
import { GeoAltFill, PlayFill, TelephoneFill, Facebook, Instagram } from 'react-bootstrap-icons';

const Footer = () => {
  return (
        <>
            <footer id="website-responsive">
                <div className="footer-bg" >
                    <div className='container-zk py-4'>
                        <div className='row pt-4 text-white gx-md-5'>
                            <div className='col-lg-6 col-md-12'>
                            <span className="fs-zk-30 text-white text-raleway text-uppercase" style={{fontWeight:"600", letterSpacing:"1px"}}>Premier Medical Clinic</span>
                                {/* <hr className='border-np-success2'/> */}
                                <p className='py-2'>
                                Medical clinic in Scottsboro, Alabama. We accept most insurance providers. Call Us for appointments!
                                </p>
                                <ul className='list-unstyled'>
                                    <li><a href='tel:+12562591234' className='text-decoration-none text-light lh-lg '><TelephoneFill className='me-2' /> 256-259-1234</a></li>
                                    {/* <li><span to='/' className='text-decoration-none text-light lh-lg'>Fax: +416-292-9117</span></li> */}
                                    <li><a href="https://goo.gl/maps/HbJDSDyCgqG3ucfa8" rel="noreferrer" target="_blank" className="text-decoration-none text-np-success"><span className=' text-light lh-lg'><GeoAltFill className='me-2' />506 Harley St Suite D, Scottsboro, AL 35768, US</span></a></li>
                                    {/* <li><span className=' text-light lh-lg fw-bold'></span></li> */}
                                    
                                </ul>

                                <div className='d-flex gap-2'>
                                    <a href='http://www.facebook.com/premiermedicalclinic1' target='_blank' className='text-decoration-none text-light lh-lg font-lg fs-4'><Facebook className='me-2' /> </a>
                                    <a href='https://www.instagram.com/premiermedicalclinic1' target='_blank' className='text-decoration-none text-light lh-lg fs-4'><Instagram className='me-2' /> </a>
                                    
                                </div>
                            </div>
                        {/* </div>
                        <div className='row text-white gx-5'> */}
                            <div className='col-lg-3 col-md-4'>
                                <div className='text-work-sans fs-zk-26' style={{fontWeight:"600"}}>Services</div>
                                <hr className='border-zk-secondary-footer my-2'/>
                                <ul className='list-unstyled mt-3'>
                                    <li><Link to='/urgent-care' className='text-decoration-none text-light lh-lg fs-zk-17'> <PlayFill className='me-1'/>Urgent Care</Link></li>
                                    <li><Link to='/primary-care' className='text-decoration-none text-light lh-lg fs-zk-17'> <PlayFill className='me-1'/>Primary Care</Link></li>
                                    <li><Link to='/cardiology' className='text-decoration-none text-light lh-lg fs-zk-17'> <PlayFill className='me-1'/>Cardiology</Link></li>
                                    <li><Link to='/pediatric-care' className='text-decoration-none text-light lh-lg fs-zk-17'> <PlayFill className='me-1'/>Pediatric Care</Link></li>
                                    <li><Link to='/internal-medicine' className='text-decoration-none text-light lh-lg fs-zk-17'> <PlayFill className='me-1'/>Internal Medicine</Link></li>
                                </ul>
                            </div>
                            <div className='col-lg-3 col-md-4'>
                                <div className='text-work-sans fs-zk-26 text-nowrap' style={{fontWeight:"600"}}>Quick Links</div>
                                <hr className='border-zk-secondary-footer my-2'/>
                                <ul className='list-unstyled mt-3'>
                                    <li><Link to='/about-us' className='text-decoration-none text-light lh-lg fs-zk-17'><PlayFill className='me-1'/>About us</Link></li>
                                    <li><Link to='/contact-us' className='text-decoration-none text-light lh-lg fs-zk-17'><PlayFill className='me-1'/>Contact Us</Link></li>
                                    {/* <li><Link to='/international-students' className='text-decoration-none text-light lh-lg fs-zk-17'>International students</Link></li> */}
                                    <li><Link to='/providers' className='text-decoration-none text-light lh-lg fs-zk-17'> <PlayFill className='me-1'/>Providers</Link></li>
                                    <li><Link to='/privacy-policy' className='text-decoration-none text-light lh-lg fs-zk-17'> <PlayFill className='me-1'/>Privacy Policy</Link></li>
                                    <li><Link to='/terms-conditions' className='text-decoration-none text-light lh-lg fs-zk-17'> <PlayFill className='me-1'/>Terms & Conditions</Link></li>
                                    {/* <li><Link to='/' className='text-decoration-none text-light lh-lg fs-zk-17'>Testimonial</Link></li> */}
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className='py-3 bg-white'>
                    <div className='container-zk'>
                        <div className='text-center'>
                            <p className='p-0 m-0'>Copyright © 2023 Premier Medical Clinic</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
  )
}

export default Footer
